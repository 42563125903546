var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket-result-number"},[_vm._l((_vm.item.lottery_result),function(result,index){return _c('div',{key:'result_c' + index,staticClass:"w-100 mb-2 mx-0 row"},[_c('p',{staticClass:"text-uppercase prizeTitle d-lg-flex col-lg-2 p-0 text-right font-15"},[_vm._v(" "+_vm._s(_vm.rewardLabel[index])+" ")]),_c('div',{staticClass:"ticket-result-row justify-content-start d-flex flex-wrap pl-lg-3 col-lg-10",class:{
        'col-lg-9': !_vm.isFullWidthResult,
        'col-12': _vm.isFullWidthResult
      }},_vm._l((result),function(number,_index){return _c('div',{key:'result_p' + index + '_' + _index + '_' + number,staticClass:"pr-3 ticket-result-row d-flex"},_vm._l((number.split('')),function(char,idx){return _c('NumberItem',{key:'numberp' + index + '_' + number + '_' + idx,staticClass:"spacingNumber",attrs:{"number":+char,"isCustomForResult":true}})}),1)}),0)])}),_c('hr'),_vm._l((_vm.item.lottery_result),function(result,index){return _c('div',{key:'result_cx' + index},[_c('div',{staticClass:"w-100 mb-2 mx-0 row"},[_c('p',{staticClass:"text-uppercase prizeTitle d-lg-flex col-lg-2 p-0 text-right font-15"},[_vm._v(" "+_vm._s(_vm.rewardLabel[index])+" ")]),_c('div',{staticClass:"ticket-result-row justify-content-start d-flex flex-wrap pl-lg-3 col-lg-10",class:{
          'col-lg-9': !_vm.isFullWidthResult,
          'col-12': _vm.isFullWidthResult
        }},_vm._l((result),function(number,_index){return _c('div',{key:'input_result_p' + index + '_' + _index,staticClass:"pr-3 pb-3",class:{
            'mw-25': _vm.isMax3D
          }},[_c('b-form-input',{attrs:{"id":'id_result_p' + index + '_' + _index,"size":"md","type":"text","placeholder":"","state":!_vm.arrError.includes('id_result_p' + index + '_' + _index),"trim":""},on:{"update":function($event){return _vm.updateInput($event, {
                key: 'id_result_p',
                index1: index,
                index2: _index
              })}},model:{value:(_vm.item.lottery_result[index][_index]),callback:function ($$v) {_vm.$set(_vm.item.lottery_result[index], _index, $$v)},expression:"item.lottery_result[index][_index]"}})],1)}),0)])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }